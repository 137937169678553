import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import Button, { PRIMARY, TRANSPARENT } from '@components/button'
import ROUTES from 'helpers/routes'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Emoji, Message, Modal, Paragraph, Title } from './style'

import { func, string } from 'prop-types'

const DuplicateAccountDetected = ({ alertKey, setAlertTypeVisibility, resetCurrentAction }) => {
  const router = useRouter()

  const onClick = () => {
    setAlertTypeVisibility(alertKey, false)
    resetCurrentAction()
  }

  const onLogin = () => {
    router.push(ROUTES.LOGIN)
  }

  const onContactSupport = () => {
    router.push({ pathname: ROUTES.VERIFY, query: { help: true } })
  }

  return (
    <AlertDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Message>
          <Title>Good to see you again!</Title>
          <Emoji>👋</Emoji>
          <Paragraph>You already have an Eaze account. Would you like to log in or contact support?</Paragraph>
        </Message>

        <ButtonContainer>
          <Button buttonType={PRIMARY} onClick={onLogin}>
            LOGIN
          </Button>
          <Button buttonType={TRANSPARENT} onClick={onContactSupport} componentStyle={{ borderColor: '#00aae7' }}>
            <ContactSupportText>CONTACT SUPPORT</ContactSupportText>
          </Button>
        </ButtonContainer>
      </Modal>
    </AlertDrawer>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

const ContactSupportText = styled.span`
  color: #00aae7;
`

DuplicateAccountDetected.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  resetCurrentAction: func
}

export default DuplicateAccountDetected
