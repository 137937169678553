import { ChangeEvent, FormEvent, Fragment, PureComponent, useState } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react'
import debounce from 'debounce'
import Image from 'next/image'
import { connect, useDispatch, useSelector } from 'react-redux'

import { resetCurrentAction } from '@/redux/alert/actions'
import { dismiss2faModal, resetProfileChange } from '@/redux/auth/actions'
import { getAuth, getUserId, getUserPhoneNumber } from '@/redux/auth/selectors'
import { openPersonaModal } from '@/redux/modal/actions'
import { fetchUserProfile } from '@/redux/profile/actions'
import Button, { PRIMARY } from '@components/button'
import { parser } from '@components/form-inputs/constants'
import { A11yHiddenLabel } from '@components/form-inputs/label'
import { track } from '@helpers/analytics'
import { apiV2 } from '@helpers/api'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '@helpers/constants'
import errorHandler from '@helpers/error-handler'
import Colors from '@microcomponents/colors'

import AlertDrawer from './alert-modal'

import { InferProps, func, shape, string } from 'prop-types'

/* OTP TYPES
 * 'sms' will send the user an OTP via text message
 * 'email' will send the user an OTP via email
 * 'phone' will send the user an OTP via phone call
 */
async function sendCode(verificationType: 'sms' | 'email' | 'phone') {
  const { err } = await apiV2.sendUserOTP({ verificationType })
  if (err) {
    errorHandler(new Error(`Error sending OTP to user: ${err.message}`))
  }
}

export default function Modal2FA() {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [userSentCode, setUserSentCode] = useState(false)
  const userId = useSelector(getUserId)
  const userPhoneNumber = useSelector(getUserPhoneNumber)
  // FUTURE TODO: use 'sms' for email changes and 'email' for phone changes
  // const authState = useSelector(getAuth)
  const typeOf2FA = 'sms' // authState.attemptProfileChange === 'phone' ? 'email' : 'sms'

  async function handleRequest() {
    setLoading(true)
    track('Verify2FA.TextMe.Click')
    await sendCode(typeOf2FA)
    setUserSentCode(true)
    setLoading(false)
  }

  function closeModal() {
    dispatch(dismiss2faModal())
  }

  function RequestOTP() {
    return (
      <ContentContainer>
        <Image src="/static/icons/Lock2.svg" alt="lock" width={30} height={24} />
        <Title>{`Let's make sure it's you`}</Title>
        <Message>{`To access and edit your account settings, we'll need to verify it's you. We will send a 4 digit code to ${
          typeOf2FA !== 'sms' ? 'your email' : `the number ending in ${userPhoneNumber.slice(-4)}`
        }, and use it to verify your identity.`}</Message>
        <Button
          buttonType={PRIMARY}
          onClick={debounce(handleRequest, 1000, true)}
          loading={isLoading}
          componentStyle={{ margin: '2rem auto', width: 'auto', padding: '1.4rem 6rem' }}>
          {typeOf2FA !== 'sms' ? `Email me` : `Text me`}
        </Button>
      </ContentContainer>
    )
  }

  return userId ? (
    <SplitClient splitKey={userId}>
      <SplitTreatments names={[SPLIT_EXPERIMENTS.PROFILE_PAGE_2FA]}>
        {({ isReady, treatments }) => {
          const treatmentIsOn =
            treatments[SPLIT_EXPERIMENTS.PROFILE_PAGE_2FA].treatment === SPLIT_TREATMENTS.PROFILE_PAGE_2FA.ON
          return isReady && treatmentIsOn ? (
            <AlertDrawer open showCloseButton onRequestClose={closeModal} modalElementClass={drawerContainerCss}>
              {userSentCode ? <ConfirmOTP /> : <RequestOTP />}
            </AlertDrawer>
          ) : null
        }}
      </SplitTreatments>
    </SplitClient>
  ) : null
}

/*
 * This is a class component instead of functional component because of the tracking event in componentDidMount
 *
 * This event should only fire once, when this screen is viewed
 *
 * If this is a functional component, adding a View tracking event causes
 * the event to be called *every* time the component renders and not just the first time
 *
 * If there is a confirmation error, this causes the component to re-render and triggers the View event a second time
 *
 * To prevent this, I'm using componentDidMount/PureComponent to limit event to first render only
 */
const mapStateToProps = (state) => ({
  authState: getAuth(state),
  userPhoneNumber: getUserPhoneNumber(state)
  // userEmail: getUserEmail(state)
})
const mapDispatchToProps = (dispatch) => ({
  fetchUserProfile: (): void => dispatch(fetchUserProfile()),
  openPersonaModal: () => dispatch(openPersonaModal()),
  resetCurrentAction: () => dispatch(resetCurrentAction()),
  resetProfileChange: () => dispatch(resetProfileChange())
})
type State = {
  code: string
  confirmationError: string
  isLoading: boolean
}
export class OTPConfirmation extends PureComponent<InferProps<typeof OTPConfirmation.propTypes>, State> {
  static propTypes = {
    authState: shape({
      attemptProfileChange: string,
      emailChangeAttempt: string,
      phoneChangeAttempt: string
    }),
    fetchUserProfile: func,
    openPersonaModal: func,
    resetCurrentAction: func,
    resetProfileChange: func,
    // userEmail: string,
    userPhoneNumber: string
  }

  state = {
    code: '',
    confirmationError: '',
    isLoading: false
  }

  componentDidMount(): void {
    track('Verify2FA.VerificationCode.View')
  }

  resendCode = async () => {
    track('Verify2FA.ResendCode')
    this.setState({ code: '', confirmationError: '' })
    // FUTURE TODO: use 'sms' for email changes and 'email' for phone changes
    // const verificationType = this.props.authState.attemptProfileChange === 'phone' ? 'email' : 'sms'
    await sendCode('sms')
  }

  handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ code: e.currentTarget.value })
  }

  handleConfirmation = async (e: FormEvent) => {
    e.preventDefault()

    this.setState({ isLoading: true })

    track('Verify2FA.VerificationCode.Submit')

    const profileChange = this.props.authState.attemptProfileChange

    let profileChangeError = null

    if (profileChange === 'phone') {
      const { err } = await apiV2.verifyPhoneWith2FA({
        to: parser(this.props.authState.phoneChangeAttempt),
        verificationType: 'sms',
        verificationCode: this.state.code
      })
      if (err) profileChangeError = err
    } else if (profileChange === 'email') {
      const { err } = await apiV2.verifyEmailWith2FA({
        to: this.props.authState.emailChangeAttempt,
        verificationType: 'sms',
        verificationCode: this.state.code
      })
      if (err) profileChangeError = err
    } else if (profileChange === 'id') {
      const { err } = await apiV2.verifyOTP({
        verificationType: 'sms',
        verificationCode: this.state.code
      })
      if (err) profileChangeError = err
    }

    if (profileChangeError) {
      track('Verify2FA.VerificationCode.ConfirmationError')
      let confirmationError: string = profileChangeError.message
      if (profileChangeError.statusCode === 403) {
        confirmationError = 'That was incorrect. Re-enter the code, or click below for a new one'
      } else if (!confirmationError.match('already registered')) {
        // if the error is something other than wrong code (403 status code) or dupe email/phone, then send error to sentry
        errorHandler(new Error(`Error confirming OTP when changing ${profileChange}: ${confirmationError}`))
      }
      this.setState({ confirmationError, isLoading: false })
    } else {
      track('Verify2FA.VerificationCode.Success')
      if (profileChange !== 'id') {
        this.props.fetchUserProfile()
      }
      this.props.resetCurrentAction()
      this.props.resetProfileChange()
      this.setState({ isLoading: false })
      if (profileChange === 'id') {
        this.props.openPersonaModal()
      }
    }
  }

  render() {
    return (
      <ContentContainer>
        <Image src="/static/icons/Lock2.svg" alt="lock" width={30} height={24} />
        <Title>Enter your code</Title>
        <form onSubmit={debounce(this.handleConfirmation, 1000, true)}>
          <A11yHiddenLabel htmlFor="code">OTP Code</A11yHiddenLabel>
          <CodeInput
            id="code"
            name="code"
            type="tel"
            required
            pattern="[0-9]{4}"
            maxLength={4}
            value={this.state.code}
            onChange={this.handleCodeChange}
            hasError={Boolean(this.state.confirmationError)}
            hasValue={Boolean(this.state.code.length >= 1)}
          />
          <Button
            type="submit"
            disabled={this.state.code.length < 1}
            componentStyle={{ margin: '2rem auto', width: 'auto', padding: '1.4rem 5.4rem' }}
            loading={this.state.isLoading}>
            Verify me
          </Button>
        </form>
        {this.state.confirmationError ? (
          <ErrorString>{this.state.confirmationError}</ErrorString>
        ) : (
          <Message>
            {/* FUTURE TODO: show phone number for email changes and email for phone changes
             `We've sent a code to ${
              this.props.authState.phoneChangeAttempt
                ? this.props.userEmail
                : `******-${this.props.userPhoneNumber.slice(-4)}`
            }.` */}
            {`We've sent a code to ******-${this.props.userPhoneNumber.slice(-4)}.`}
            <br />
            {`If you don't receive a code within 5 min, please click below, we'll send another.`}
          </Message>
        )}
        <ButtonLink type="button" onClick={debounce(this.resendCode, 1000, true)}>
          Resend Code
        </ButtonLink>
        {this.state.confirmationError && (
          <Fragment>
            <SupportMessage>Trouble receiving your code?</SupportMessage>
            <SupportLink href="https://help.eaze.com/hc/en-us" target="_blank" rel="noopener noreferrer">
              Support Center
            </SupportLink>
          </Fragment>
        )}
      </ContentContainer>
    )
  }
}

const ConfirmOTP = connect(mapStateToProps, mapDispatchToProps)(OTPConfirmation)

const drawerContainerCss = css`
  height: calc(100%);
  position: absolute;
  background: white;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  outline: none;
  top: 0;
  left: 0;
  text-align: center;
  padding: 2rem 3.75rem 0;
  font-family: 'Suisse Intl';
  padding-top: 10rem;

  @media (min-width: 768px) {
    overflow-y: auto;
    left: initial;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const ContentContainer = styled.div`
  max-width: 480px;
`

const Title = styled.h2`
  font-size: 2rem;
  letter-spacing: 0.01em;
  line-height: 1.42rem;
  margin: 2rem auto;
`

const CodeInput = styled.input<{ hasError: boolean; hasValue: boolean }>`
  height: 4rem;
  width: 100%;
  text-align: center;
  font-size: 2rem;

  border: ${({ hasError }) => (hasError ? '2px' : '1px')} solid
    ${({ hasError, hasValue }) => (hasError ? Colors.danger[1] : hasValue ? Colors.homepageRedesign[0] : '#E5E5E5')};
  outline-color: ${({ hasError }) => (hasError ? Colors.danger[1] : Colors.homepageRedesign[0])};
`

// color: ${(props) => props.theme.colors.grey};
const Message = styled.p`
  color: ${Colors.accessory[1]};
  margin: 0;
`

const SupportMessage = styled.p`
  margin-top: 10rem;
`

const SupportLink = styled.a`
  text-decoration: underline !important;
`

// color: ${(props) => props.theme.colors.eazeBlue};
const ButtonLink = styled.button`
  background: none;
  border: 0;
  margin: 1rem auto;
  padding: 0;
  color: ${Colors.homepageRedesign[5]};
  text-decoration: underline;
  cursor: pointer;
`

// color: ${(props) => props.theme.colors.danger};
const ErrorString = styled.p`
  color: ${Colors.danger[1]};
`
