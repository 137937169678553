import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react'
import { useSelector } from 'react-redux'

import { getUserId } from '@/redux/auth/selectors'
import { getPhoneNumberBasic } from '@/redux/profile/selectors'
import Button, { PRIMARY } from '@components/button'
import { apiV2 } from '@helpers/api'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '@helpers/constants'
import errorHandler from '@helpers/error-handler'
import ROUTES from 'helpers/routes'

import AlertDrawer from './alert-modal'
import { ButtonWrapper, Emoji, Logout, LogoutLink, Message, Modal, Paragraph, Title } from './style'

import { InferProps, func, shape } from 'prop-types'

PhoneVerificationIncomplete.propTypes = {
  logout: func,
  router: shape({
    push: func
  })
}
export default function PhoneVerificationIncomplete(props: InferProps<typeof PhoneVerificationIncomplete.propTypes>) {
  const userPhoneNumber = useSelector(getPhoneNumberBasic)
  const userId = useSelector(getUserId)

  async function handleNavigation() {
    if (userPhoneNumber) {
      const { err } = await apiV2.verifyPhoneNumber({ verificationType: 'sms' })
      if (err) {
        errorHandler(new Error(`Encountered error sending OTP from verify phone modal: ${err.message}`), {user: {id: userId}})
      }
    }
    props.router.push(ROUTES.VERIFY)
  }

  return (
    <AlertDrawer open allowClose={false}>
      <SplitClient splitKey={userId}>
        <SplitTreatments names={[SPLIT_EXPERIMENTS.PROFILE_PAGE_2FA]}>
          {({ isReady, treatments }) => {
            const splitIsOn =
              treatments[SPLIT_EXPERIMENTS.PROFILE_PAGE_2FA].treatment === SPLIT_TREATMENTS.PROFILE_PAGE_2FA.ON
            if (isReady && splitIsOn && userPhoneNumber) {
              return (
                <Modal>
                  <Message>
                    <Title>Verify your new phone</Title>
                    <Emoji>📱</Emoji>
                    <Paragraph>
                      {`We'll need to verify your new phone number ending in ${userPhoneNumber.slice(
                        -4
                      )} before saving it to your account`}
                    </Paragraph>
                  </Message>
                  <ButtonWrapper>
                    <Button buttonType={PRIMARY} onClick={handleNavigation}>
                      Get verified
                    </Button>
                  </ButtonWrapper>
                </Modal>
              )
            } else {
              return (
                <Modal>
                  <Message>
                    <Title>Verify your phone</Title>
                    <Emoji>📱</Emoji>
                    <Paragraph>To complete Eaze sign up, you need to verify your phone number.</Paragraph>
                  </Message>
                  <ButtonWrapper>
                    <Button buttonType={PRIMARY} onClick={handleNavigation}>
                      Get verified
                    </Button>
                  </ButtonWrapper>
                  <Logout>
                    <LogoutLink href="/login" onClick={props.logout}>
                      Sign in as another user
                    </LogoutLink>
                  </Logout>
                </Modal>
              )
            }
          }}
        </SplitTreatments>
      </SplitClient>
    </AlertDrawer>
  )
}
